"use client";

import { useRouter, useSearchParams } from "next/navigation";
import React, { Fragment, useCallback, useRef, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { signIn, SignInResponse } from "next-auth/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginFormSchema } from "@/lib/validators/account-form-schema";
import { Button } from "@/components/ui";
import AuthSocialButton from "./AuthSocialButton";
import Link from "next/link";
import { Variant } from "@/types/authType";
import { LoginErrorType } from "@/types/LoginErrorType";
import { logger } from "@/lib/logging/logger";
import axios from "axios";
import StyledInput from "./StyledInput";
import { googleSvg } from "./svgs";
import AlertMessage from "@/app/[locale]/(dashboard)/contact_us/components/AlertMessage";
import { useTranslation } from "react-i18next";
import { useTranslatedErrors } from "@/lib/i18n/translationUtils";

const AuthForm = ({
  isModal = false,
  setVariantState,
  redirectTo = "/home",
}: {
  isModal?: boolean;
  setVariantState?: (variant: Variant) => void;
  redirectTo?: string;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { t } = useTranslation(["login", "zodErrors"]);
  const emailParam = searchParams.get("email");
  const error = searchParams.get("error");
  const isOAuthError = error === "OAuthAccountNotLinked";
  const callbackUrl = searchParams.get("callbackUrl");
  const loginType = searchParams.get("loginType");
  const successParam = searchParams.get("successMessage");

  const [isLoading, setIsLoading] = useState(false);
  const [loginProvider, setLoginProvider] = useState<"email" | "google">(
    "email",
  );
  const [submitStatus, setSubmitStatus] = useState<
    "idle" | "error" | "success"
  >(error ? "error" : successParam ? "success" : "idle");
  const [errorMessage, setErrorMessage] = useState(
    isOAuthError ? t("oAuthError") : error || "",
  );
  const [successMessage, setSuccessMessage] = useState(successParam || "");
  const [verificationData, setVerificationData] = useState({
    display: false,
    email: "",
    password: "",
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      email: emailParam || "",
      password: "",
      ...(loginType && { loginType }),
    },
    resolver: zodResolver(loginFormSchema),
    mode: "onChange",
    criteriaMode: "all",
  });
  console.log("errors", errors);
  const email = watch("email");
  const translatedErrors = useTranslatedErrors(errors, t);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setIsLoading(true);
    setLoginProvider("email");

    const finalRedirectUrl =
      callbackUrl && !callbackUrl.includes("/register")
        ? callbackUrl
        : redirectTo;

    signIn("credentials", {
      ...data,
      redirect: false,
    })
      .then((callback) =>
        handleSignInCallback(callback, data, finalRedirectUrl),
      )
      .finally(() => {
        if (formRef.current) {
          formRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setIsLoading(false);
      });
  };

  const handleSendVerificationEmail = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      return await axios.post("/api/verify-email/send_verification", {
        email: email,
        password: password,
      });
    },
    [],
  );

  const handleSignInCallback = (
    callback: SignInResponse | undefined,
    data: FieldValues,
    finalRedirectUrl: string,
  ) => {
    if (callback?.error) {
      setSubmitStatus("error");

      if (callback?.error === LoginErrorType.EMAIL_NOT_VERIFIED) {
        setErrorMessage(callback?.error);
        setVerificationData({
          display: true,
          email: data.email,
          password: data.password,
        });
      } else if (callback?.error === LoginErrorType.INVALID_CREDENTIALS) {
        setVerificationData((prev) => ({
          ...prev,
          display: false,
        }));
        setErrorMessage(t("invalidCredetials"));
      } else if (
        callback?.error === LoginErrorType.WRONG_PROVIDER &&
        callback?.ok
      ) {
        router.push(finalRedirectUrl);
        router.refresh();
      }
    } else if (callback?.ok) {
      router.push(finalRedirectUrl);
      router.refresh();
    }
  };

  const socialAction = (action: string) => {
    setIsLoading(true);
    setLoginProvider("google");
    signIn(action, { redirect: false })
      .then((callback) => {
        if (callback?.error) {
          setErrorMessage(t("socialActionError"));
          setVerificationData((prev) => ({
            ...prev,
            display: false,
          }));
          setSubmitStatus("error");
        } else if (callback?.ok) {
          router.push(redirectTo);
        }
      })
      .finally(() => {
        if (formRef.current) {
          formRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setIsLoading(false);
      });
  };

  const renderAdminMode = () => (
    <div className="flex flex-col gap-2">
      <p className="text-red-500 text-center font-bold">{t("admin")}</p>
      <p className="text-sm text-red-500 text-center">{t("adminDetails")}</p>
    </div>
  );

  const renderForm = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="self-stretch h-max flex-col justify-start items-center gap-8 flex"
        ref={formRef}
      >
        {submitStatus !== "idle" && (
          <div className="self-stretch items-center text-center max-w-[412px]">
            <AlertMessage
              status={submitStatus}
              errorMessage={errorMessage}
              successMessage={successMessage}
            />
            {verificationData.display && (
              <button
                type="button"
                onClick={async () => {
                  try {
                    await handleSendVerificationEmail({
                      email: verificationData.email,
                      password: verificationData.password,
                    });
                    setSuccessMessage("Verification email sent!");
                    setVerificationData((prev) => ({
                      ...prev,
                      display: false,
                    }));
                    setSubmitStatus("success");
                  } catch (err: any) {
                    logger.info(err);
                    setErrorMessage(
                      `${err.response.data.error}` ||
                        "Could not send mail. Please try again later.",
                    );
                    setSubmitStatus("error");
                  }
                }}
                className="text-slate-700 underline hover:text-slate-400"
              >
                Send verification email
              </button>
            )}
          </div>
        )}
        {loginType === "adminMode" && renderAdminMode()}
        <div className="self-stretch h-max flex-col justify-start items-start gap-6 flex">
          <StyledInput
            disabled={isLoading}
            register={register}
            errors={translatedErrors}
            required
            id="email"
            label={t("email")}
            errorsShow
          />
          <StyledInput
            disabled={isLoading}
            register={register}
            errors={translatedErrors}
            required
            type="password"
            id="password"
            label={t("password")}
            errorsShow
          />
        </div>
        <div className="self-stretch h-max flex-col justify-start items-center gap-4 flex">
          <Button
            disabled={isLoading}
            type="submit"
            className="hover:bg-blue-600 transition-colors text-white text-sm font-semibold leading-tight self-stretch h-[42px] px-4 py-2 bg-brand-secondary rounded-lg border border-white justify-center items-center gap-1 inline-flex"
          >
            {isLoading && loginProvider === "email"
              ? t("loginActive")
              : t("login")}
          </Button>
          <AuthSocialButton
            icon={googleSvg()}
            disabled={isLoading}
            onClick={() => socialAction("google")}
            provider="Google"
            action={
              isLoading && loginProvider === "google"
                ? t("loginProviderActive")
                : t("loginProvider")
            }
          />
        </div>
      </form>
    );
  };

  const renderFormActions = () => {
    return (
      <div className="self-stretch h-max flex-col justify-start items-center gap-2.5 flex">
        <div className="flex justify-center gap-1 self-stretch text-center">
          <span className="text-slate-600 text-base font-normal  leading-normal">
            {t("new")}
          </span>
          {!isModal && (
            <Link
              prefetch={false}
              href={`/register${email ? `?email=${encodeURIComponent(email)}` : ""}`}
              className="cursor-pointer text-brand-secondary text-base font-normal  underline leading-normal"
            >
              {t("register")}
            </Link>
          )}
          {isModal && setVariantState && (
            <div
              className="cursor-pointer text-brand-secondary text-base font-normal  underline leading-normal"
              onClick={() => setVariantState("REGISTER")}
            >
              {t("register")}
            </div>
          )}
        </div>
        <div className="flex justify-center gap-1 self-stretch text-center">
          <span className=" text-slate-600 text-base font-normal  leading-normal">
            {t("forgot")}
          </span>
          {!isModal && (
            <Link
              prefetch={false}
              href={`/reset${email ? `?email=${encodeURIComponent(email)}` : ""}`}
              className="text-brand-secondary text-base font-normal  underline leading-normal"
            >
              {t("reset")}
            </Link>
          )}
          {isModal && setVariantState && (
            <div
              className="cursor-pointer text-brand-secondary text-base font-normal  underline leading-normal"
              onClick={() => setVariantState("FORGOT_PASSWORD")}
            >
              {t("reset")}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderForm()}
      {renderFormActions()}
    </Fragment>
  );
};

export default AuthForm;
