import { z } from "zod";

export const passwordSchema = z
  .string()
  .min(8, "passwordErrors.min8")
  .max(20, "passwordErrors.max20")
  .refine((pass) => /(?=.*[0-9])/.test(pass), "passwordErrors.digit")
  .refine((pass) => /(?=.*[a-z])/.test(pass), "passwordErrors.lowercase")
  .refine((pass) => /(?=.*[A-Z])/.test(pass), "passwordErrors.uppercase")
  .refine((pass) => /(?=.*\W)/.test(pass), "passwordErrors.special");

export const passwordUnionAccDetails = z.object({
  password: z.string().min(1, "passwordErrors.currentRequired"),
  newPassword: z.union([passwordSchema, z.string().nullish()]),
});

export const passwordSchemaAccountDetails = z.union([
  z
    .string()
    .min(8, "passwordErrors.min8")
    .max(20, "passwordErrors.max20")
    .refine((pass) => /(?=.*[0-9])/.test(pass), "passwordErrors.digit")
    .refine((pass) => /(?=.*[a-z])/.test(pass), "passwordErrors.lowercase")
    .refine((pass) => /(?=.*[A-Z])/.test(pass), "passwordErrors.uppercase")
    .refine((pass) => /(?=.*\W)/.test(pass), "passwordErrors.special"),
  z.string().nullish(),
]);

export const passwordRestraints = z
  .object({
    password: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwordErrors.match",
    path: ["confirmPassword"],
  });

export const passwordChangeRestraints = z
  .object({
    password: passwordSchema,
    confirmPassword: z.string(),
    email: z.string().email(),
    token: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwordErrors.match",
    path: ["confirmPassword"],
  });
