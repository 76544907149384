"use client";

import clsx from "clsx";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { useState } from "react";
import {
  FieldErrors,
  FieldValues,
  RegisterOptions,
  UseFormClearErrors,
  UseFormRegister,
} from "react-hook-form";
import InputError from "./InputError";

interface InputProps {
  label: string;
  id: string;
  type?: string;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
  errors?: FieldErrors;
  disabled?: boolean;
  readonly?: boolean;
  options?: RegisterOptions<FieldValues, string>;
  errorsShow?: boolean;
}

const StyledInput: React.FC<InputProps> = ({
  label,
  id,
  register,
  required,
  errors,
  type = "text",
  disabled,
  readonly,
  options,
  errorsShow,
}) => {
  const [isPassword, setIsPassword] = useState(type === "password");
  const [typeForPassword, setTypeForPassword] = useState(type);

  return (
    <div className="flex flex-col w-full h-max gap-1">
      <div className="text-slate-600 text-sm font-semibold leading-tight">
        {label}
      </div>
      <div className="relative h-full w-full bg-white rounded-lg border border-slate-200 justify-start items-center gap-2.5 inline-flex">
        <input
          placeholder={label}
          id={id}
          type={isPassword ? typeForPassword : type}
          autoComplete={id}
          disabled={disabled}
          readOnly={readonly}
          {...register(id, { required: `${label} is required`, ...options })}
          className={clsx(
            "grow shrink basis-0 text-xs px-4 py-3 font-normal w-full leading-none h-full",
            errors && errors[id] && errorsShow && "ring-rose-300",
            disabled && "opacity-50 cursor-default",
          )}
        />
        {isPassword && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 top-0 pr-3 pt-1 flex"
            onClick={() =>
              setTypeForPassword(
                typeForPassword === "text" ? "password" : "text",
              )
            }
          >
            {typeForPassword === "text" ? (
              <EyeOffIcon className="p-1 h-7 w-7 hover:bg-slate-300 rounded-full transition-colors" />
            ) : (
              <EyeIcon className="p-1 h-7 w-7 hover:bg-slate-300 rounded-full transition-colors" />
            )}
          </button>
        )}
      </div>
      <div className="flex flex-col">
        {errors && (
          <div className="text-xs text-red-500">
            {errors[id]?.message as string}
          </div>
        )}
      </div>
    </div>
  );
};

export default StyledInput;
