import { z } from "zod";
import { languages, TLanguageCode } from "countries-list";

// Create a type-safe array of language codes
export const validLanguageCodes = Object.keys(languages) as TLanguageCode[];

export const userTypeOptions = [
  { value: "player", label: "player" },
  { value: "coach", label: "coach" },
  { value: "organizer", label: "organizer" },
  { value: "parent", label: "parent" },
  { value: "club", label: "club" },
];

export const discoveryMethodOptions = [
  { value: "search", label: "search" },
  { value: "social", label: "social" },
  { value: "friend", label: "friend" },
  { value: "tournament", label: "tournament" },
  { value: "advertisement", label: "advertisement" },
];

// Type for user type values
type UserType = (typeof userTypeOptions)[number]["value"];
// Type for discovery method values
type DiscoveryMethod = (typeof discoveryMethodOptions)[number]["value"];

export const onboardingSchema = z.object({
  name: z
    .string()
    .min(2, "stringLengthErrors.min2")
    .max(50, "stringLengthErrors.max50")
    .trim()
    .refine((val) => /^[a-zA-Z\s\-'.]+$/.test(val), {
      message: "typeErrors.nameIllegalChars",
    }),

  language: z
    .string()
    .refine(
      (val): val is TLanguageCode =>
        validLanguageCodes.includes(val as TLanguageCode),
      {
        message: "typeErrors.invalidLanguage",
      },
    ),

  userType: z.union([
    z.enum(
      userTypeOptions.map((opt) => opt.value) as [UserType, ...UserType[]],
    ),
    z
      .string()
      .min(2, "stringLengthErrors.min2")
      .max(50, "stringLengthErrors.max50")
      .refine(
        (val) =>
          !userTypeOptions.map((opt) => opt.value).includes(val as UserType),
        {
          message: "typeErrors.invalidCustom",
        },
      ),
  ]),

  discoveryMethod: z.union([
    z.enum(
      discoveryMethodOptions.map((opt) => opt.value) as [
        DiscoveryMethod,
        ...DiscoveryMethod[],
      ],
    ),
    z
      .string()
      .min(2, "stringLengthErrors.min2")
      .max(100, "stringLengthErrors.max100")
      .refine(
        (val) =>
          !discoveryMethodOptions
            .map((opt) => opt.value)
            .includes(val as DiscoveryMethod),
        {
          message: "typeErrors.invalidCustom",
        },
      ),
  ]),
});

export type onboardingValues = z.infer<typeof onboardingSchema>;
