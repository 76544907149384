import clsx from "clsx";
import React from "react";
import { IconType } from "react-icons";

interface AuthSocialButtonProps {
  icon: React.ReactNode;
  onClick: () => void;
  provider: string;
  action?: string;
  disabled?: boolean;
}

const AuthSocialButton: React.FC<AuthSocialButtonProps> = ({
  icon: Icon,
  onClick,
  provider,
  action = "Log in with",
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "self-stretch w-full hover:bg-neutral-200 transition-colors h-[42px] px-4 py-2 bg-white rounded-lg border text-brand-secondary border-brand-secondary justify-center items-center gap-1 inline-flex",
        disabled && "opacity-50 cursor-default",
      )}
      type="button"
      disabled={disabled}
    >
      {Icon}
      <span className="">
        {action} {provider}
      </span>
    </button>
  );
};

export default AuthSocialButton;
