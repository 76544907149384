interface AlertMessageProps {
  status: "idle" | "success" | "error";
  errorMessage: string | null;
  successMessage?: string;
}

export default function AlertMessage({
  status,
  errorMessage,
  successMessage,
}: AlertMessageProps) {
  if (status === "success") {
    return (
      <div
        className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg"
        role="alert"
      >
        {successMessage}
      </div>
    );
  }

  if (status === "error" && errorMessage) {
    return (
      <div
        className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
        role="alert"
      >
        {errorMessage}
      </div>
    );
  }

  return null;
}
