import {
  passwordSchema,
  passwordUnionAccDetails,
} from "@/types/change-password-form-type";
import { TLanguageCode } from "countries-list";
import { z } from "zod";
import { validLanguageCodes } from "./onboarding-schema";

export const userAccountFormSchema = passwordUnionAccDetails.extend({
  name: z
    .string()
    .min(2, {
      message: "stringLengthErrors.min2",
    })
    .max(30, {
      message: "stringLengthErrors.max30",
    }),
  email: z
    .string({
      required_error: "typeErrors.invalidEmail",
    })
    .email("typeErrors.invalidEmail"),
  showMovePopup: z.boolean().default(false),
  language: z
    .string()
    .refine(
      (val): val is TLanguageCode =>
        validLanguageCodes.includes(val as TLanguageCode),
      {
        message: "typeErrors.invalidLanguage",
      },
    ),
});

export type UserAccountFormValues = z.infer<typeof userAccountFormSchema>;

export const adminUserAccountFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "stringLengthErrors.min2",
    })
    .max(30, {
      message: "stringLengthErrors.max30",
    }),
  email: z
    .string({
      required_error: "typeErrors.invalidEmail",
    })
    .email(),

  role: z.string().optional(),
  isInternal: z.boolean().optional(),
});

export type AdminUserAccountFormValues = z.infer<
  typeof adminUserAccountFormSchema
>;

export const signUpFormSchema = z
  .object({
    name: z
      .string()
      .min(2, {
        message: "stringLengthErrors.min2",
      })
      .max(30, {
        message: "stringLengthErrors.max30",
      })
      .optional(),
    email: z
      .string({
        required_error: "typeErrors.invalidEmail",
      })
      .email(),
    password: passwordSchema,
    confirmPassword: z
      .string()
      .min(1, { message: "requiredFields.confirmPassword" }),
    refId: z
      .union([
        z.string().length(0, { message: "stringLengthErrors.equals25" }),
        z.string().length(25, { message: "stringLengthErrors.equals25" }),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    promoId: z
      .string()
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    redirectTo: z.string().optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwordMatch",
    path: ["confirmPassword"],
  });

export const loginFormSchema = z.object({
  email: z.string().email({ message: "typeErrors.invalidEmail" }),
  password: z
    .string({
      required_error: "requiredFields.password",
    })
    .min(1, { message: "requiredFields.password" }),
  loginType: z.string().optional(),
});

export type LoginFormValues = z.infer<typeof loginFormSchema>;

export type SignUpFormValues = z.infer<typeof signUpFormSchema>;
